import "./HostHome.scss";
import a_homepageImages from "../../assets/images/home/a_homepage";
import h_homepageImages from "../../assets/images/home/h_homepage";
import {
  HostSplitInformation,
  SplitInformation,
} from "../../lib/components/homeTools/splitInformation/component";
import { Trans } from "react-i18next";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { Link } from "react-router-dom";
import Toast from "../../components/toast/component";
import { Helmet } from "react-helmet";
import { Testimony } from "../../lib/components/homeTools/testimony/testimony";
import { useHostHome } from "../../hooks/hostHome/useHostHome";
import { useWindowSize } from "../../hooks/windowSize";
import { Steps } from "../../lib/components/homeTools/steps/steps";
import { BenefitsCarousel } from "../../lib/components/homeTools/benefitsCarousel/benefitsCarousel";

export const HostHome = () => {
  const windowSize = useWindowSize();

  const {
    t,
    errorMsg,
    openAlert,
    setOpenAlert,
    values,
    setValues,
    brandsSuccess,
    brands,
    years,
    yearsSuccess,
    types,
    typesSuccess,
    versions,
    versionsSuccess,
    handleAddCar,
    loading,
  } = useHostHome();

  return (
    <>
      <Helmet>
        <title>{t("seo.title")}</title>
        <meta name="description" content={t("seo.description")} />
        <meta property="og:title" content={t("seo.og_title")} />
        <meta property="og:description" content={t("seo.og_description")} />
        <meta name="robots" content="index,follow" />

        <link rel="canonical" href="https://romio.mx/anfitrion" />
        <meta property="og:url" content="https://romio.mx/anfitrion" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={t("seo.og_locale")} />
        <link rel="alternate" hrefLang="es" href="https://romio.mx/anfitrion" />
        <link
          rel="alternate"
          hrefLang="en"
          href="https://romio.mx/en/anfitrion"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://romio.mx/anfitrion"
        />
      </Helmet>
      <main id="a_homepage">
        {errorMsg && (
          <Toast open={openAlert} setOpen={setOpenAlert} type="error">
            {errorMsg}
          </Toast>
        )}
        <div className="a-find">
          <div className="row a-find-content">
            <div className="content">
              <div className="col-12 col-sm-12 col-md-6">
                <h3 data-testid="share-your-car-title">
                  {t("find_car.share_your_car")}
                </h3>
                <h4 data-testid="share-your-car-subtitle">
                  {t("find_car.earn_money_renting")}
                </h4>
              </div>
              <div className="selectors-container">
                <div className="selectors">
                  <div className="selector">
                    <FormControl fullWidth data-testid="select-brand">
                      <p data-testid="brand-label">
                        {t("find_car.brand")} <span className="pink">*</span>
                      </p>
                      <Select
                        labelId="brand"
                        id="select_brand"
                        className="select-characteristics"
                        value={values.carBrand}
                        onChange={(e) =>
                          setValues({ ...values, carBrand: e.target.value })
                        }
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected == "") {
                            return t("find_car.q_brand");
                          }
                          return selected;
                        }}
                        disabled={!brandsSuccess}
                        error={!values.carBrand}
                      >
                        <MenuItem disabled value="">
                          {t("find_car.q_brand")}
                        </MenuItem>
                        {brands?.map((brand, index: number) => (
                          <MenuItem
                            data-testid={`select-brand-${index}`}
                            key={brand.cMarcaLarga}
                            value={brand.cMarcaLarga}
                          >
                            {brand.cMarcaLarga}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="selector">
                    <FormControl fullWidth data-testid="select-model">
                      <p data-testid="model-label">
                        {t("find_car.model")} <span className="pink">*</span>
                      </p>
                      <Select
                        labelId="model"
                        id="select_model"
                        className="select-characteristics"
                        value={values.carType}
                        onChange={(e) =>
                          setValues({ ...values, carType: e.target.value })
                        }
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected == "") {
                            return t("find_car.q_model");
                          }
                          return selected;
                        }}
                        disabled={!typesSuccess}
                        error={!values.carType}
                      >
                        <MenuItem disabled value="">
                          {t("find_car.q_model")}
                        </MenuItem>
                        {types?.map((type: string, index: number) => (
                          <MenuItem
                            key={type}
                            value={type}
                            data-testid={`select-model-${index}`}
                          >
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="selector">
                    <FormControl fullWidth data-testid="select-year">
                      <p data-testid="year-label">
                        {t("find_car.year")} <span className="pink">*</span>
                      </p>
                      <Select
                        labelId="year"
                        id="select_year"
                        className="select-characteristics"
                        value={values.carYear}
                        onChange={(e) =>
                          setValues({ ...values, carYear: e.target.value })
                        }
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected == "") {
                            return t("find_car.q_year");
                          }
                          return selected;
                        }}
                        disabled={!yearsSuccess}
                        error={!values.carYear}
                      >
                        <MenuItem disabled value="">
                          {t("find_car.q_year")}
                        </MenuItem>
                        {years?.map((year: string, index: number) => (
                          <MenuItem
                            key={year}
                            value={year}
                            data-testid={`select-year-${index}`}
                          >
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="selector">
                    <FormControl fullWidth data-testid="select-version">
                      <p>
                        {t("find_car.version")} <span className="pink">*</span>
                      </p>
                      <Select
                        labelId="version"
                        id="version"
                        className="select-characteristics"
                        value={values.carVersion}
                        onChange={(e) =>
                          setValues({ ...values, carVersion: e.target.value })
                        }
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected == "") {
                            return t("find_car.q_version");
                          }
                          return selected;
                        }}
                        disabled={!versionsSuccess}
                        error={!values.carVersion}
                      >
                        <MenuItem disabled value="">
                          {t("find_car.version")}
                        </MenuItem>
                        {versions?.map((version: string, index: number) => (
                          <MenuItem
                            key={version}
                            value={version}
                            data-testid={`select-version-${index}`}
                          >
                            {version}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                {errorMsg && (
                  <div className="error-message">
                    <p>Error: {errorMsg}</p>{" "}
                  </div>
                )}
                <div className="btn-container">
                  <button
                    id="SaveView"
                    type="button"
                    className="btn btn-orange"
                    onClick={handleAddCar}
                    disabled={loading}
                    data-testid="add-car-host"
                  >
                    {t("find_car.raise_my_car")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Swiper
            data-testid="carousel"
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                src={a_homepageImages.girlCarousel}
                alt="a girl with a phone"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img src={a_homepageImages.driveCarousel} alt="a driver" />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={a_homepageImages.boxesCarousel}
                alt="people packing up"
              />
            </SwiperSlide>
          </Swiper>
          <div className="carousel-vector-container">
            <img src={a_homepageImages.carouselVector} alt="Carousel vector" />
          </div>
          <div className="a-find-img-container">
            <img
              src={a_homepageImages.girlCarouselMobile}
              alt="a girl with a phone"
            />
          </div>
        </div>
      </main>

      <article className="a-info pt-0">
        <HostSplitInformation
          principalClass="a-win"
          imageClass="col-md-6"
          title={
            <h3 data-testid="speed-up-earnings-title">
              {t("speed_up_earnings.title")}
            </h3>
          }
          subtitle={
            <h4 data-testid="speed-up-earnings-subtitle">
              {t("speed_up_earnings.share")}
              <span className="pink">{t("speed_up_earnings.pink")}</span>
              {t("speed_up_earnings.rent_cars")}
            </h4>
          }
          content={
            <>
              <Trans>
                <p>{t("speed_up_earnings.do_not_lose_time")}</p>
                <p>{t("speed_up_earnings.join_today")}</p>
              </Trans>
            </>
          }
          image={
            <img
              src={
                windowSize < 800
                  ? a_homepageImages.redCarMobile
                  : a_homepageImages.redCar
              }
              className="w-100"
              alt="a person with a lot of money"
            />
          }
          componentColor={""}
          componentOrder={"order-top-right col-md-6"}
          hasHeader={true}
        />
        <div className="speed-up-earnings-vector-container">
          <img
            src={a_homepageImages.speedUpVector}
            alt="Speed up eranings vector"
          />
        </div>
      </article>

      <article className="benefits-container-anfitrion">
        <div className="title-container">
          <h2 data-testid="benefits-title" className="title">
            {t("benefits.why_host")}
          </h2>
          <p data-testid="benefits-subtitle">{t("benefits.with_you")}</p>
        </div>
        <div className="list-benefits">
          <div className="benefit">
            <img src={a_homepageImages.moneyPerk} alt="a hand holding a coin" />
            <h3>{t("benefits.earn_more")}</h3>
            <p>{t("benefits.increase_earnings")}</p>
          </div>
          <div className="benefit">
            <img src={a_homepageImages.listPerk} alt="a list beeing checked" />
            <h3>{t("benefits.free_listing")}</h3>
            <p>{t("benefits.upload_your_car")}</p>
          </div>
          <div className="benefit">
            <img src={a_homepageImages.pricePerk} alt="a phone with a price" />
            <h3>{t("benefits.you_put_price")}</h3>
            <p>{t("benefits.determine_your_car_price")}</p>
          </div>
          <div className="benefit">
            <img src={a_homepageImages.datesPerk} alt="calendra" />
            <h3>{t("benefits.control_dates")}</h3>
            <p>{t("benefits.manage_disponibility")}</p>
          </div>
          <div className="benefit">
            <img src={a_homepageImages.wayPerk} alt="a path to an ubication" />
            <h3>{t("benefits.delivery_points")}</h3>
            <p>{t("benefits.select_delibery_devolution")}</p>
          </div>
          <div className="benefit">
            <img src={a_homepageImages.carRulesPerk} alt="a clean car" />
            <h3>{t("benefits.your_rules")}</h3>
            <p>{t("benefits.feel_calm")}</p>
          </div>
          <div className="benefit">
            <img
              src={a_homepageImages.verifiedPerk}
              alt="a person holding a shield"
            />
            <h3>{t("benefits.verified_users")}</h3>
            <p>{t("benefits.inspection_process")}</p>
          </div>
          <div className="benefit">
            <img
              src={a_homepageImages.controlPerk}
              alt="someone choosing a person"
            />
            <h3>{t("benefits.you_have_control")}</h3>
            <p>{t("benefits.each_trip")}</p>
          </div>
          <div className="benefit">
            <img
              src={a_homepageImages.insurancePerk}
              alt="a lock in a shield"
            />
            <h3>{t("benefits.insured_trips")}</h3>
            <p>{t("benefits.qualitas_insurance")}</p>
          </div>
          <div className="benefit">
            <img src={a_homepageImages.discountPerk} alt="a price tag" />
            <h3>{t("benefits.exclusive_discounts")}</h3>
            <p>{t("benefits.enjoy_benefits")}</p>
          </div>
        </div>
      </article>

      <BenefitsCarousel />

      <div className="steps-container">
        <Steps
          stepList={[
            <div className="step">
              <img src={h_homepageImages.unOne} alt="number one" />
              <div className="step-info">
                <h3 data-testid="step-one-title">{t("steps.sign_up")}</h3>
                <p>{t("steps.create_your_profile")}</p>
              </div>
            </div>,
            <div className="step">
              <img src={h_homepageImages.unTwo} alt="number two" />
              <div className="step-info">
                <h3 data-testid="step-two-title">
                  {t("steps.get_your_car_up")}
                </h3>
                <p>{t("steps.set_your_price")}</p>
              </div>
            </div>,
            <div className="step">
              <img src={h_homepageImages.unThree} alt="number three" />
              <div className="step-info">
                <h3 data-testid="step-three-title">
                  {t("steps.greets_your_driver")}
                </h3>
                <p>{t("steps.give_him_the_keys")}</p>
              </div>
            </div>,
            <div className="step">
              <img src={h_homepageImages.unFour} alt="number three" />
              <div className="step-info">
                <h3 data-testid="step-four-title">
                  {t("steps.relax_and_win")}
                </h3>
                <p>{t("steps.reservation_deposit")}</p>
              </div>
            </div>,
          ]}
          title={
            <div className="steps-title-container">
              <h3 data-testid="steps-title">{t("steps.how_romio_work?")}</h3>
              <p data-testid="steps-subtitle">{t("steps.start_to_win")}</p>
            </div>
          }
          image={
            <img
              src={
                windowSize < 800
                  ? a_homepageImages.romioWorksMobile
                  : a_homepageImages.romioWorks
              }
              alt="a person using a phone"
            />
          }
        />
        <div className="sprinkles-vector-container">
          <img src={a_homepageImages.sprinklesVector} alt="Sprinkles vector" />
        </div>
      </div>

      <article className="a-info">
        <div className="row a-sec">
          <div className="content">
            <div className="col-12 col-sm-12 col-md-6 col-xl-5 content-text">
              <h3 data-testid="insurance-title">
                {t("insurance_trips.title")}
              </h3>
              <h4 data-testid="insurance-subtitle">
                {t("insurance_trips.insured_all_time")}
              </h4>
              <Trans>
                <p>{t("insurance_trips.hdi_alliance")}</p>
                <p>{t("insurance_trips.hdi_alliance_extra")}</p>
              </Trans>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title-text">
                    {t("insurance_trips.comprehensive_coverage.title")}
                  </h5>
                  <ul className="list-left">
                    <li>
                      <img src={a_homepageImages.checkInsurance} alt="" />
                      {t(
                        "insurance_trips.comprehensive_coverage.civil_liability"
                      )}
                    </li>
                    <li>
                      <img src={a_homepageImages.checkInsurance} alt="" />
                      {t(
                        "insurance_trips.comprehensive_coverage.material_damage"
                      )}
                    </li>
                    <li>
                      <img src={a_homepageImages.checkInsurance} alt="" />
                      {t("insurance_trips.comprehensive_coverage.total_theft")}
                    </li>
                    <li>
                      <img src={a_homepageImages.checkInsurance} alt="" />
                      {t(
                        "insurance_trips.comprehensive_coverage.medical_expenses"
                      )}
                    </li>
                    <li>
                      <img src={a_homepageImages.checkInsurance} alt="" />
                      {t(
                        "insurance_trips.comprehensive_coverage.legal_expenses"
                      )}
                    </li>
                    <li>
                      <img src={a_homepageImages.checkInsurance} alt="" />
                      {t(
                        "insurance_trips.comprehensive_coverage.roadside_assistance"
                      )}
                    </li>
                  </ul>
                  <a>{t("insurance_trips.comprehensive_coverage.read_more")}</a>
                </div>
              </div>
              {windowSize > 768 && (
                <Link
                  to={`/${t("signup", { ns: "routes" })}`}
                  className="btn btn-orange"
                >
                  {t("insurance_trips.start_now")}
                </Link>
              )}
            </div>
            <div className="col-12 col-sm-12 col-md-6 content-img">
              <img
                src={
                  windowSize < 800
                    ? a_homepageImages.insuranceMobile
                    : a_homepageImages.insurance
                }
                className="w-100"
                alt="a car in a road"
              />
            </div>
            {windowSize < 768 && (
              <Link
                to={`/${t("signup", { ns: "routes" })}`}
                className="btn btn-orange"
              >
                {t("insurance_trips.start_now")}
              </Link>
            )}
          </div>
        </div>
      </article>

      <article className="a-info pt-0">
        <div className="testimony-one-vector-container">
          <img
            src={a_homepageImages.testimonyOneVector}
            alt="Testimony vector"
          />
        </div>
        <div className="testimony-one-vector-container-mobile">
          <img
            src={a_homepageImages.testimonyOneVectorMobile}
            alt="Testimony vector mobile"
          />
        </div>
        <Testimony
          componentColor={""}
          testimonies={[
            {
              image: (
                <img src={a_homepageImages.firstTestimony} alt="first person" />
              ),
              name: (
                <>
                  <h3 className="name" data-testid="first-name">
                    {t("testimony_container.first_testimony.name")}
                  </h3>
                  <span className="car">
                    {t("testimony_container.first_testimony.car")}
                  </span>
                </>
              ),
              testimony: (
                <p className="testimony-text" data-testid="first-testimony">
                  {t("testimony_container.first_testimony.testimony")}
                </p>
              ),
            },
            {
              image: (
                <img
                  src={a_homepageImages.secondTestimony}
                  alt="second person"
                />
              ),
              name: (
                <>
                  <h3 className="name" data-testid="second-name">
                    {t("testimony_container.second_testimony.name")}
                  </h3>
                  <span className="car">
                    {t("testimony_container.second_testimony.car")}
                  </span>
                </>
              ),
              testimony: (
                <p className="testimony-text" data-testid="second-testimony">
                  {t("testimony_container.second_testimony.testimony")}
                </p>
              ),
            },
            {
              image: (
                <img src={a_homepageImages.thirdTestimony} alt="third person" />
              ),
              name: (
                <>
                  <h3 className="name" data-testid="third-name">
                    {t("testimony_container.third_testimony.name")}
                  </h3>
                  <span className="car">
                    {t("testimony_container.third_testimony.car")}
                  </span>
                </>
              ),
              testimony: (
                <p className="testimony-text" data-testid="third-testimony">
                  {t("testimony_container.third_testimony.testimony")}
                </p>
              ),
            },
          ]}
        />
        <div className="testimony-three-vector-container">
          <img
            src={a_homepageImages.testimonyThreeVector}
            alt="Testimony vector"
          />
        </div>
        <div className="testimony-three-vector-container-mobile">
          <img
            src={a_homepageImages.testimonyThreeVectorMobile}
            alt="Testimony mobile"
          />
        </div>
      </article>

      <article className="a-info pt-0">
        <SplitInformation
          title={
            <h3 data-testid="trusted-community-title">
              {t("trusted_community.title")}
            </h3>
          }
          subtitle={
            <h4 data-testid="trusted-community-subtitle">
              {t("trusted_community.subtitle")}
            </h4>
          }
          content={
            <>
              <Trans>
                <p>{t("trusted_community.information")}</p>
                <p>{t("trusted_community.information_extra")}</p>
              </Trans>
              <Link to={t("host", { ns: "routes" })} className="btn btn-orange">
                {t("trusted_community.upload_car")}
              </Link>
            </>
          }
          image={
            <img
              src={a_homepageImages.conversation}
              className="w-100"
              alt="people talking"
            />
          }
          componentColor={""}
          componentOrder={"order-top-left"}
        />
        <div className="community-vector-container">
          <img src={a_homepageImages.communityVector} alt="Community vector" />
        </div>
      </article>

      <article className="a-info pt-0">
        <HostSplitInformation
          principalClass="un-lote"
          imageClass="img-container col-md-6"
          title={
            <h3 data-testid="hostC-information-title">{t("car_lot.title")}</h3>
          }
          subtitle={
            <h4 data-testid="hostC-information-subtitle">
              {t("car_lot.rental_lot")}
            </h4>
          }
          content={
            <Link
              to={`/${t("commercial_host", { ns: "routes" })}`}
              className="btn btn-orange"
            >
              {t("car_lot.learn_more")}
            </Link>
          }
          image={
            <img
              src={
                windowSize < 800
                  ? a_homepageImages.loteMobile
                  : a_homepageImages.lote
              }
              id=""
              className="w-100"
              alt="a girl with keys"
            />
          }
          componentColor={""}
          componentOrder={"order-top-right col-md-6 col-xl-5"}
          hasHeader={false}
        />
        <div className="arrow-vector-container">
          <img src={a_homepageImages.arrowVector} alt="Arrow vector" />
        </div>
      </article>

      <article className="a-info last-section-bg">
        <HostSplitInformation
          principalClass="last"
          imageClass="col-md-12"
          title={
            <h3 className="ready" data-testid="earn-money-title">
              {t("earn_money.are_you_ready?")}
            </h3>
          }
          subtitle={
            <h4 className="join" data-testid="earn-money-subtitle">
              {t("earn_money.join_now")}
            </h4>
          }
          content={
            <Link
              to={`/${t("signup", { ns: "routes" })}`}
              className="btn btn-orange"
            >
              {t("earn_money.Register")}
            </Link>
          }
          componentColor={""}
          componentOrder={""}
          hasHeader={true}
        />
      </article>
    </>
  );
};
