import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { HostCallToAction } from "../../lib/components/profileBoxesTools/hostCallToAction/component";
import { HostCommercialInfo } from "../../lib/components/profileBoxesTools/hostCommercialInfo/component";
import { InviteFriends } from "../../lib/components/profileBoxesTools/inviteFriends/component";
import { PayMethod } from "../../lib/components/profileBoxesTools/payMethod/component";
import { User } from "../../models/user/types";
import "./styles.scss";
import { DomainContext } from "../../providers/domainProvider";

export const ProfileBoxes: FC<User> = ({ ...user }) => {
  const { t } = useTranslation(["profile"]);
  const { settings } = useContext(DomainContext);

  return (
    <article className="profile-boxes">
      <div className="boxes-container">
        <div className="profile-update">
          <h2 data-testid="payment-information-title">
            {t("profile_boxes.payment_information")}
          </h2>
        </div>

        <PayMethod />

        <HostCommercialInfo />

        <InviteFriends />

        <div className="dropdown-divider"></div>
        {settings.routes.homeHost && <HostCallToAction {...user} />}
      </div>
    </article>
  );
};
