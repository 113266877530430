import dayjs from "dayjs";
import { createContext, useEffect, useMemo } from "react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { TFunction, Trans, useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import h_homepage from "../../../assets/images/home/h_homepage";
import i18n from "../../../i18n";
import carImages from "../../../assets/images/car";
import {
  ActiveActions,
  CancelActions,
  Contracts,
  FinishActions,
  NextActions,
  PendingActions,
} from "../../../lib/components/driverReservationTools/component";
import { mockTrip } from "../../../models/trip/mockups";
import { TripStatus } from "../../../models/trip/types";
import { getCar } from "../../../services/car";
import { getTrip } from "../../../services/trip";
import { getUserByID } from "../../../services/user";
import "./styles.scss";
import { statusList } from "./types";
import { Trip } from "../../../models/trip/types";
import { User } from "../../../models/user/types";
import { defaultUser } from "../../../models/user/mockups";
import { mockCar } from "../../../models/car/mockups";
import { Car } from "../../../models/car/types";
import {
  getFileDetailsIfExist,
  uploadioEnv,
} from "../../../services/fileUploader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  formatIdHexToDecimal,
  numberSeparator,
} from "../../../lib/helpers/textHandler";
import Loading from "../../../components/loading/component";
import { EmptyState } from "../../../lib/components/resultsTools/emptyState/component";
import { HostCard } from "../../../lib/components/carTools/carForm/hostCard/HostCard";
import { getInsuranceByCarId } from "../../../services/insurance";
import { InsuranceBanner } from "../../../lib/components/driverReservationTools/insuranceBanner/InsuranceBanner";
import { Chip } from "@mui/material";
import {
  CarExperiences,
  CarSpecs,
} from "../../../lib/components/carTools/carForm/cardInfo/CardInfo";
import { TooltipIcon } from "../../../components/tooltips/component";
import { formatPrice } from "../../../components/carResultCard/utils";
import { ResponsibilitiesBanner } from "../../../lib/components/driverReservationTools/responsibilitiesBanner/ResponsibilitiesBanner";
import { AddressText } from "../../../lib/components/driverReservationTools/addressText/AddressText";
import a_cars from "../../../assets/images/a_cars";
import { Helmet } from "react-helmet";
import { getUserId } from "../../../services/localstorage";
import { TripCompleteProfile } from "../../../components/modals/tripCompleteProfile/tripCompleteProfile";

export const TranslationContext = createContext<
  TFunction<"translation", undefined>
>(i18n.t);

export const TripContext = createContext<{
  trip: Trip | undefined;
  host: User | undefined;
  car: Car | undefined;
  t: TFunction<"translation", undefined>;
}>({ trip: mockTrip, host: defaultUser, car: mockCar, t: i18n.t });

export const DriverReservation = () => {
  const currentUser = getUserId();
  const { t } = useTranslation(["driverReservation", "routes"]);
  const { reservationId: id } = useParams();
  const reservationId = useMemo(() => id && formatIdHexToDecimal(id), [id]);
  const navigate = useNavigate();

  const {
    data: trip,
    isSuccess,
    isLoading,
  } = useQuery(["tripDriver"], () => getTrip(reservationId));

  useEffect(() => {
    if (!trip || !currentUser) return;

    if (trip.driverUserId.toString() !== currentUser) {
      navigate(
        "/" +
          t("results", {
            ns: "routes",
          })
      );
    }
  }, [trip]);

  const { data: host } = useQuery(
    ["host", trip?.hostId],
    () => getUserByID(trip?.hostId),
    { enabled: isSuccess }
  );

  const { data: insuranceCar } = useQuery(
    ["insuranceCar", trip?.carId],
    () => getInsuranceByCarId(trip?.carId),
    {
      enabled: !!trip,
    }
  );

  const { data: car } = useQuery(
    ["car", trip?.carId],
    () => getCar(trip?.carId.toString()),
    { enabled: isSuccess }
  );

  const { data: carImage } = useQuery(
    ["image", trip?.carId],
    () =>
      getFileDetailsIfExist(
        `/${uploadioEnv}/user-${trip?.hostId}/car-${trip?.carId}/car_photo_1`,
        `/${uploadioEnv}/user-${trip?.hostId}/car-${trip?.carId}`
      ),
    { enabled: isSuccess }
  );

  if (isLoading) {
    return <Loading height="25vh" />;
  }

  if (!trip) {
    return <EmptyState />;
  }

  const circleIconColor =
    trip.status === TripStatus.PAYED_NOT_AUTHORIZED
      ? "#E7E7E7"
      : trip.status === TripStatus.SEND_REQUEST_RESERVATION
      ? "#FFAB03"
      : trip.status === TripStatus.ACCEPT_RESERVATION
      ? "#f76f8e"
      : trip.status === TripStatus.ACTIVE_RESERVATION
      ? "#55DD55"
      : trip.status === TripStatus.FINISH_RESERVATION
      ? "#3CB2B9"
      : trip.status === TripStatus.REFUSE_REQUEST_ANFITRION
      ? "#E97451"
      : "#EB5757";

  const circleLabel =
    trip.status === TripStatus.PAYED_NOT_AUTHORIZED
      ? t("index.status.incomplete")
      : trip.status === TripStatus.SEND_REQUEST_RESERVATION
      ? t("index.status.pending")
      : trip.status === TripStatus.ACCEPT_RESERVATION
      ? t("index.status.next")
      : trip.status === TripStatus.ACTIVE_RESERVATION
      ? t("index.status.active")
      : trip.status === TripStatus.FINISH_RESERVATION
      ? t("index.status.finished")
      : trip.status === TripStatus.REFUSE_REQUEST_ANFITRION
      ? t("index.status.refused")
      : t("index.status.cancel");

  const actions =
    trip.status === TripStatus.PAYED_NOT_AUTHORIZED ? (
      <TripCompleteProfile isOpen={true} />
    ) : trip.status === TripStatus.SEND_REQUEST_RESERVATION ? (
      <PendingActions />
    ) : trip.status === TripStatus.ACCEPT_RESERVATION ? (
      <NextActions />
    ) : trip.status === TripStatus.ACTIVE_RESERVATION ? (
      <ActiveActions />
    ) : trip.status === TripStatus.FINISH_RESERVATION ? (
      <FinishActions />
    ) : trip.status === TripStatus.CANCEL_RESERVATION_ANFITRION ||
      trip.status === TripStatus.CANCEL_RESERVATION_HUESPED ||
      trip.status === TripStatus.CANCEL_RESERVATION_BALETY ? (
      <CancelActions />
    ) : null;

  return (
    <>
      <Helmet>
        <title>{t("seo.show.title", { code: trip.tripCode })}</title>
        <meta name="description" content={t("seo.show.description")} />
        <meta name="robots" content="noindex,follow" />
        <link
          rel="alternate"
          hrefLang="es"
          href={`https://romio.mx/huespedes/reservaciones/${id}`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`https://romio.mx/en/huespedes/reservaciones/${id}`}
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`https://romio.mx/huespedes/reservaciones/${id}`}
        />
      </Helmet>
      <TripContext.Provider value={{ t, trip, host, car }}>
        <div className="driver-reservation-container">
          <div className="car-form">
            <div className="card-info">
              <div className="return-container">
                <Link
                  to={
                    "/" +
                    t("drivers", { ns: "routes" }) +
                    "/" +
                    t("trips", { ns: "routes" })
                  }
                  className="reservation-index"
                >
                  <img src={h_homepage.unPrevDes} alt="" />
                  <span data-testid="return-link" className="return-link">
                    <Trans i18nKey="show.reservation_details.title">
                      {t("show.reservation_details.title") +
                        t(
                          "show.reservation_details." +
                            statusList[trip?.status ? trip?.status : 0]
                        )}
                    </Trans>
                  </span>
                </Link>
              </div>

              <HostCard userId={trip.hostId} trip={trip} />

              <button type="button" className="btn btn-confirmation-code">
                {t("show.code") + trip?.tripCode}
              </button>

              <div className="card-car-information">
                <h5 className="card-title">
                  {car && car.brand + " " + car.model + " " + car.year}
                </h5>
                <h6 className="card-placas">{car?.plate}</h6>
                <article id="" className="return-car">
                  <div style={{ fontSize: "6px" }}>
                    <Chip
                      data-testid="button-status-driver"
                      icon={
                        <CircleIcon
                          fontSize="inherit"
                          style={{
                            color: circleIconColor,
                          }}
                        />
                      }
                      label={circleLabel}
                      sx={{
                        position: "absolute",
                        top: "8px",
                        left: "8px",
                        background: "white",
                        height: "24px",
                        zIndex: 4,
                      }}
                    />
                  </div>
                  <img
                    className="car-img"
                    src={
                      carImage
                        ? carImage.fileUrl.replace(
                            "raw",
                            "thumbnail-reservations"
                          )
                        : a_cars.searchCar
                    }
                    alt=""
                  />
                </article>
              </div>

              {car && <CarExperiences car={car} />}
              {car && <CarSpecs car={car} />}

              <div className="contracts-container">
                <Contracts />
              </div>
            </div>

            <div className="card">
              <button type="button" className="btn btn-confirmation-code-des">
                {t("show.code") + trip?.tripCode}
              </button>
              <div className="card-body">
                <div className="un-container-date">
                  <div className="un-desde">
                    <label className="w-100" data-testid="since-label">
                      {t("show.trip_summary.since")}
                    </label>
                    <div className="input-container">
                      <div className="input-text mb-0">
                        <p className="pickUpDate">
                          {dayjs(trip.pickUpDate, "DD-MM-YYYY").format(
                            "DD/MM/YYYY"
                          )}
                        </p>
                      </div>
                      <div className="dropdown-divider"></div>
                      <div className="input-text mb-0">
                        <p className="pickUpTime">
                          {dayjs(trip.pickUpTime, "HH:mm:ss").format("h:mm A")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="un-hasta">
                    <label data-testid="until-label">
                      {t("show.trip_summary.until")}
                    </label>
                    <div className="input-container">
                      <div className="input-text mb-0">
                        <p className="returnDate">
                          {dayjs(trip.returnDate, "DD-MM-YYYY").format(
                            "DD/MM/YYYY"
                          )}
                        </p>
                      </div>
                      <div className="dropdown-divider"></div>
                      <div className="input-text">
                        <p className="returnTime">
                          {dayjs(trip.returnTime, "HH:mm:ss").format("h:mm A")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 delibery">
                  <label data-testid="delivery-label">
                    {t("show.trip_summary.delivery")}
                  </label>
                  <AddressText
                    addressId={trip.pickUpAddressId}
                    tripStatus={trip.status}
                  />
                </div>
                <div className="col-sm-12 col-md-12 delibery devolution">
                  <label data-testid="return-label">
                    {t("show.trip_summary.return")}
                  </label>
                  <AddressText
                    addressId={trip.returnAddressId}
                    tripStatus={trip.status}
                  />
                </div>
                <div className="extra d-flex col-sm-12 col-md-12">
                  <div className="day-tag">
                    <h5 data-testid="car-label">
                      {t("show.trip_summary.car")}
                    </h5>
                    <TooltipIcon
                      title={t(
                        "car:show.car_form.information_tooltips.use_days"
                      )}
                      icon={<InfoOutlinedIcon />}
                      classes="tooltip-form"
                    />
                    <p className="price-day">
                      ${car?.price + t("show.by_day")}
                    </p>
                  </div>
                  <span className="carPriceTotal">
                    $
                    {numberSeparator(
                      parseFloat(trip?.carPrice ? trip?.carPrice : "")
                    )}
                  </span>
                </div>
                <div className="dropdown-divider col-sm-12 col-md-12"></div>
                <div className="extra col-sm-12 col-md-12">
                  <div className="day-tag">
                    <h5 data-testid="insurance-label">
                      {t("show.trip_summary.insurance")}
                    </h5>
                    <TooltipIcon
                      title={t(
                        "car:show.car_form.information_tooltips.insurance"
                      )}
                      icon={<InfoOutlinedIcon />}
                      classes="tooltip-form"
                    />
                    <p className="insurance-day">
                      ${insuranceCar?.customerPrice + t("show.by_day")}
                    </p>
                  </div>
                  <span id="insuranceTotal">
                    ${numberSeparator(parseFloat(trip.insurancePrice || ""))}
                  </span>
                </div>
                <div className="dropdown-divider col-sm-12 col-md-12"></div>
                <div className="extra col-sm-12 col-md-12">
                  <div className="day-tag">
                    <h5 data-testid="booking_fee-label">
                      {t("show.trip_summary.booking_fee")}
                    </h5>
                    <TooltipIcon
                      title={t(
                        "car:show.car_form.information_tooltips.comission"
                      )}
                      icon={<InfoOutlinedIcon />}
                      classes="tooltip-form"
                    />
                  </div>
                  <span className="bookingFeePrice">
                    ${trip.bookingFeePrice}
                  </span>
                </div>
                <div className="dropdown-divider col-sm-12 col-md-12"></div>
                <div className="extra col-sm-12 col-md-12">
                  <div className="day-tag">
                    <h5 data-testid="delivery-return-label">
                      {t("show.trip_summary.delivery_return")}
                    </h5>
                    <TooltipIcon
                      title={t("car:show.car_form.information_tooltips.prices")}
                      icon={<InfoOutlinedIcon />}
                      classes="tooltip-form"
                    />
                  </div>
                  <span className="pickUpReturnPrice">
                    ${trip.pickUpReturnPrice}
                  </span>
                </div>
                <div className="dropdown-divider col-sm-12 col-md-12"></div>
                <div className="extra col-sm-12 col-md-12">
                  <div className="day-tag">
                    <h5 data-testid="delivery-return-label">
                      {t("show.trip_summary.discount")}
                    </h5>
                  </div>
                  <span className="pickUpReturnPrice">
                    {trip.discount !== "0" ? `-$${trip.discount}` : "-"}
                  </span>
                </div>
                <div className="dropdown-divider col-sm-12 col-md-12"></div>
                <div className="total col-sm-12 col-md-12">
                  <h5 className="total-label" data-testid="total-cost-label">
                    {t("show.trip_summary.total_cost")}
                  </h5>
                  <p className="totalPrice">
                    <span>
                      {formatPrice(
                        parseFloat(trip.totalPrice || "") -
                          parseFloat(
                            trip.discount !== " " ? trip.discount : "0"
                          )
                      )}
                    </span>
                    MXN
                  </p>
                </div>
                {trip.safetyDepositAmount !== "" && (
                  <div className="warning">
                    <button type="button" className="btn alert-triangle">
                      <img src={carImages.alertTriangle} alt="" />
                    </button>
                    <div className="text-container">
                      <p>
                        <Trans>
                          {t("create.warning", {
                            deposit: `$${numberSeparator(
                              parseFloat(trip.safetyDepositAmount || "0")
                            )}`,
                          })}
                        </Trans>
                      </p>
                    </div>
                  </div>
                )}
                {actions}
              </div>
            </div>

            <div className="contracts-container-mobile">
              <Contracts />
            </div>
          </div>
          {insuranceCar && (
            <Swiper
              slidesPerView={1}
              loop
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 7000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="banner-swipper"
            >
              <SwiperSlide>
                <InsuranceBanner trip={trip} />
              </SwiperSlide>
              <SwiperSlide>
                <ResponsibilitiesBanner />
              </SwiperSlide>
            </Swiper>
          )}
        </div>
      </TripContext.Provider>
    </>
  );
};
