import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { getUser } from "../../services/user";
import { useAuth } from "../../hooks/auth";
import { login } from "../../services/auth";
import { sha256 } from "js-sha256";
import { AuthDTO } from "../../models/auth/types";
import {
  deleteIdAndEmail,
  deleteIsCompleted,
  deleteRemainingSeconds,
  deleteToken,
  getToken,
} from "../../services/localstorage";
import { AxiosError } from "axios";
import { useWindowSize } from "../../hooks/windowSize";
import { LoginContext } from "../../hooks/root";
import { formatRoute, handleRoutes } from "../../lib/helpers/routesHandler";
import { DomainContext } from "../../providers/domainProvider";

export const useNavbar = () => {
  const { hostComercialId } = useContext(DomainContext);
  const { isLogged, setIsLogged } = useContext(LoginContext);
  const [burgerMenuShow, setBurgerMenuShow] = useState<boolean>(false);
  const windowSize = useWindowSize();
  const [clicks, setClicks] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(["navbar", "routes"]);
  const { haveToken } = useAuth();
  const queryClient = useQueryClient();

  const [urlType, setUrlType] = useState("driver");

  const { data } = useQuery(["profile"], getUser, {
    enabled: isLogged && !!getToken(),
  });

  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const matchSignup = useMatch(
    t("complete_profile", {
      ns: "routes",
    })
  );

  const matchVerify = useMatch(
    t("verify", {
      ns: "routes",
    })
  );

  const ERROR_MESSAGE = {
    "Password Incorrect": t("error_validation.wrong_password"),
    "Email Not Exists": t("error_validation.email_not_exist"),
  };

  // On click menu burger close
  useEffect(() => {
    if (burgerMenuShow) setBurgerMenuShow(false);
  }, [clicks]);

  // On change location menu burger close
  useEffect(() => {
    if (burgerMenuShow) setBurgerMenuShow(false);
  }, [location]);

  // Know if a user is logged
  useEffect(() => {
    if (!haveToken) {
      setIsLogged(false);
    } else {
      setIsLogged(true);
    }
  }, [haveToken]);

  const fetchLogin = async (values: AuthDTO) => {
    setErrorMsg(null);
    try {
      // Login on navbar
      await login({
        email: values.email,
        password: sha256(values.password),
        id: hostComercialId,
      });
      setIsLogged(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorMessage: keyof typeof ERROR_MESSAGE =
          error.response?.data.message;
        const message = ERROR_MESSAGE[errorMessage]
          ? ERROR_MESSAGE[errorMessage]
          : error.response?.data.message;
        setErrorMsg(message);
      }
    }
  };

  // Logout function to delete all generated data
  const logout = () => {
    deleteIdAndEmail();
    deleteIsCompleted();
    deleteToken();
    deleteRemainingSeconds();
    setIsLogged(false);
    navigate(
      "/" +
        t("results", {
          ns: "routes",
        })
    );
    queryClient.invalidateQueries({ queryKey: ["userLogged"] });
  };

  useEffect(() => {
    const specificUrl = formatRoute();
    const route = handleRoutes(specificUrl);

    if (route) {
      setUrlType(route);
    }
  }, [location.pathname]);

  return {
    t,
    matchSignup,
    matchVerify,
    data,
    windowSize,
    fetchLogin,
    logout,
    urlType,
    errorMsg,
    isLogged,
    burgerMenuShow,
    setBurgerMenuShow,
    setClicks,
  };
};
